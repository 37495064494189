// 销售开卡相关api

import request from "@/utils/request";

/**
 * 销售单输入框，获取用户输入的历史数据
 */
export function getUserInputHistory(field) {
  return request({
    url: `/seller/tradeSell/get/historyInFo/${field}`,
    method: 'get',
    loading: false
  })
}

/**
 * 获取企业下店铺列表
 */
export function getShopList(params) {
  return request({
    // url: 'seller/shops/getShopChildList',
    url:'/seller/shops/getShopChildListTwo',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取销售订单记录
 */
// export function getSaleOrderList(params) {
//   return request({
//     url: '/seller/tradeSell',
//     method: 'get',
//     params
//   })
// }
export function getSaleOrderList(params) {
  return request({
    url: '/seller/tradeSell/new',
    method: 'get',
    params
  })
}

/**
 * 获取记录详情
 */
export function getSaleOrderDetail(id) {
  return request({
    url: `/seller/tradeSell/${id}/tradeSellInfo`,
    method: 'get'
  })
}
/**
 * 获取记录详情new
 */
export function getSaleOrderDetail2(id) {
  return request({
    url: `/seller/tradeSell/new/${id}/tradeSellInfo`,
    method: 'get'
  })
}



/**
 * 修改销售订单记录
 */
export function updateSaleOrderRecord(params, id) {
  return request({
    url: `/seller/tradeSell/editTradeSell?id=${id}`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 导入卡号识别卡号
 */

export function exportCard(params) {
  return request({
    url: '/seller/tradeSell/autoIdentify',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 根据店铺ID获取卡券列表
 */
export function getShopCardList(id,appTypeShopTypeFlag) {
  return request({
    url: `/seller/tradeSell/${id}/${appTypeShopTypeFlag}`,
    method: 'get'
  })
}

// 根据关卡单号销售单编号和商城id查询卡券2.0 企业开卡回显卡券
export  function getShopCardList2(params) {
  return request({
    url: `/seller/card/close/seller/cards/queryCardInFo/open`,
    method: 'get',
    params
  })
}


/**
 * 编辑卡券数量
 */
export function editCardCouponInfo(params) {
  return request({
    url: '/seller/tradeSell/editCardNumber',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 编辑卡券卡号段
 */
export function editCardCouponRange(params) {
  return request({
    url: '/seller/tradeSell/editCardRange',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 保存信息
 */
export function saveCardSale(params) {
  return request({
    url: '/seller/tradeSell/addTradeSell',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 卡号查询
 */
export function cardSearch(params) {
  return request({
    url: '/seller/card/cardNumList',
    method: 'get',
    params
  })
}

// ------------ 以下为销售退单接口
/**
 * 获取销售退单列表
 */
// export function getSaleRefundList(params) {
//   return request({
//     url: '/seller/refundSell',
//     method: 'get',
//     params
//   })
// }
export function getSaleRefundList(params) {
  return request({
    url: '/seller/refundSellNew',
    method: 'get',
    params
  })
}

/**
 * 获取销售退单详情
 */
export function getSaleRefundDetail(id) {
  return request({
    url: `/seller/refundSell/${id}/refundSellInfo`,
    method: 'get'
  })
}

/**
 * 根绝销售单号查询详情
 */
export function getSnSaleOrderDetail(sn) {
  return request({
    url: `/seller/refundSell/${sn}/tradeSellInfo`,
    method: 'get',
  })
}

/**
 * 更新销售退单详情
 */
export function updateSaleRefundRecord(params, id) {
  return request({
    url: `/seller/refundSell/editRefundSell?id=${id}`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 销售退单识别卡号段
 */
export function saleRefundAutoIdentify(params) {
  return request({
    url: '/seller/refundSell/autoIdentify',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 编辑退单卡号数量
 */
export function editRefundCardNum(params) {
  return request({
    url: '/seller/refundSell/editCardNumber',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 编辑退单卡号区间
 */
export function editRefundCardRange(params) {
  return request({
    url: '/seller/refundSell/editCardRange',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 添加退单记录
 */
export function addSaleRefundRecord(params) {
  return request({
    url: '/seller/refundSell/addRefundRecordSell',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 取消销售订单/退单
 */
export function cancelOrder(order_type, id) {
  return request({
    url: `/seller/tradeSell/cancelOrder?id=${id}&order_type=${order_type}`,
    method: 'get'
  })
}

/**
 * 退单页获取销售单的所有订单号
 */
export function getAllSaleOrderSnList() {
  return request({
    url: '/seller/refundSell/tradeSellSnList',
    method: 'get'
  })
}


/**
 * 查询卡销售订单列表销售关卡选择器
*/
export function getCardInfoList(params) {
  return request({
    url: '/seller/trade/orders/seller/cards/queryClose',
    method: 'get',
    params
  })
}

/**
 * 关卡核销列表
*/
export function getCloseCardList(params) {
  return request({
    url: '/seller/card/underWrite/getCloseCardList',
    method: 'get',
    params
  })
}

/**
 * 开卡核销列表
*/
export function getCloseOpenCardList(params) {
  return request({
    url: '/seller/card/underWrite/getCloseOpenCardList',
    method: 'get',
    params
  })
}


/**
 * 关卡功能
 */
export function updateCardState(params) {
  return request({
    url: '/seller/card/underWrite/updateCardState',
    method: 'PUT',
    params,
  })
}


/**
 * 开卡功能
 */
export function updateCloseOpenState(params) {
  return request({
    url: '/seller/card/underWrite/updateCloseOpenState',
    method: 'PUT',
    params,
  })
}


/**
 * 关卡详情
 */
export function closeCardDetails(params) {
  return request({
    url: '/seller/card/underWrite/closeCardDetails',
    method: 'GET',
    params,
  })
}

/**
 * 开卡详情
 */
export function closeOpenCardDetails(params) {
  return request({
    url: '/seller/card/underWrite/closeOpenCardDetails',
    method: 'GET',
    params,
  })
}

/**
 * 录入单号
 */
export function autoIdentify(params) {
  return request({
    url: '/seller/tradeSell/new/autoIdentify',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}


// 导入
export function editNewCardCouponInfo(params) {
  return request({
    url: '/seller/tradeSell/new/editCardRangeOrCardNum',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}

// 销售关卡
export function getSaleCloseList(params){
  return request({
    url: '/seller/card/close/closeCardList',
    method: 'GET',
    params,
  })
}

// 销售开卡
export function getSaleOpenList(params){
  return request({
    url: '/seller/card/close/closeOpenCardList',
    method: 'GET',
    params,
  })
}

// 取消关卡
export function cancelCloseCard(params){
  return request({
    url: '/seller/card/close/cancelCloseCard',
    method: 'GET',
    params,
  })
}

// 取消开卡
export function cancelCloseCardOpen(params){
  return request({
    url: '/seller/card/close/cancelCloseCardOpen',
    method: 'GET',
    params,
  })
}



// 关卡单打印
export function closeCardPrint(params){
  return request({
    url: '/seller/card/close/closeCardPrint',
    method: 'GET',
    params,
  })
}

// 开卡单打印
export function closeOpenCardPrint(params){
  return request({
    url: '/seller/card/close/closeOpenCardPrint',
    method: 'GET',
    params,
  })
}

// 卡券查询
export function getCardNumList(params){
  return request({
    url: '/seller/card/cardNumList',
    method: 'GET',
    params,
  })
}

// 根据销售单编号查询单个详情卡销售2.0
export function queryCloseInFo(params){
  return request({
    url: '/seller/card/close/seller/cards/queryCloseInFo',
    method: 'GET',
    params,
  })
}

// 部分关卡 录入卡号校验 closeVO.getCloseSn()有值时为修改
export function sectionCloseCardInPutCheckout(params) {
  return request({
    url: '/seller/card/close/sectionCloseCardInPutCheckout',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}


// 企业整单关卡挑选确认
export function closeSelectCheckout(params){
  return request({
    url: '/seller/card/close/seller/cards/closeSelectCheckout',
    method: 'GET',
    params,
  })
}

// 销售关卡获取该企业的客户名称
export function getCustomer(params){
  return request({
    url: '/seller/tradeSell/getCustomer',
    method: 'GET',
    params,
  })
}

// 卡销售2.0整单关卡 close_sn有值为修改
export function closeCard(params) {
  return request({
    url: '/seller/card/close/closeCard',
    method: 'POST',
    params,
    headers: {'Content-Type': 'application/json'},
  })
}

// 关卡单详情
export function closeCardDetails2(params) {
  return request({
    url: '/seller/card/close/closeCardDetails',
    method: 'GET',
    params,
  })
}


// 整单开卡根据关卡号查询关卡单详情
export function openCardDetails(params) {
  return request({
    url: '/seller/card/close/closeCardDetails/open',
    method: 'GET',
    params,
  })
}

// 整单开卡保存 close_open_sn有值时为修改
export function openSave(params) {
  return request({
    url: '/seller/card/close/closeCard/openSave',
    method: 'GET',
    params,
  })
}

// 开卡单详情
export function closeOpenCardDetails3(params) {
  return request({
    url: '/seller/card/close/closeOpenCardDetails',
    method: 'GET',
    params,
  })
}

// 根据销售单编号和卡券id查询可以进行关卡的卡销售订单区间2.0
export function querySellCard(params) {
  return request({
    url: '/seller/card/close/seller/cards/querySellCard',
    method: 'GET',
    params,
  })
}

// 部分关卡 对卡券进行校验 closeVO.getCloseSn()有值时为修改
export function sectionCloseCardCheckout(params) {
  return request({
    url: '/seller/card/close/sectionCloseCardCheckout',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}

// 添加卡券销售订单记录
export function addTradeSell(params){
  return request({
    url: '/seller/tradeSell/new/addTradeSell',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}


// 卡销售2.0部分关卡保存
export function sectionCloseCard(params){
  return request({
    url: '/seller/card/close/sectionCloseCard',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}


// 修改卡券销售订单记录
export function editTradeSell2(params,id){
  return request({
    url: '/seller/tradeSell/new/editTradeSell',
    method: 'PUT',
    params:{id},
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}

// 根据关卡单编号查询单个详情卡销售2.0 企业开卡回显销售单号
export function queryCloseOpenInFo(params) {
  return request({
    url: '/seller/card/close/seller/cards/queryCloseInFo/open',
    method: 'GET',
    params,
  })
}

// 部分开卡 录入卡号校验 closeVO.getCloseSn()有值时为修改
export function sectionOpenCardInPutCheckout(params){
  return request({
    url: '/seller/card/close/sectionCloseCardInPutCheckout/open',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}


// 根据关卡单编号和销售单编号 企业开卡回显商城
export function queryCloseCardsShop(params) {
  return request({
    url: '/seller/card/close/seller/cards/queryShop/open',
    method: 'GET',
    params,
  })
}

export function sectionCloseOpenCardCheckout(params){
  return request({
    url: '/seller/card/close/sectionCloseCardCheckout/open',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}

export function sectionCloseCardOpen(params){
  return request({
    url: '/seller/card/close/sectionCloseCardOpen',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}


export function queryTradeSellInfo(sn) {
  return request({
    url: `/seller/refundSellNew/${sn}/tradeSellInfo`,
    method: 'GET',
  })
}

export function editRefundCardRangeOrCardNum(params){
  return request({
    url: '/seller/refundSellNew/editCardRangeOrCardNum',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}

export function refundAutoIdentify(params){
  return request({
    url: '/seller/refundSellNew/autoIdentify',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}


export function refundSellNewInfo(id) {
  return request({
    url: `/seller/refundSellNew/${id}/refundSellInfo`,
    method: 'GET',
  })
}

export function addRefundNewRecordSell(params){
  return request({
    url: '/seller/refundSellNew/addRefundRecordSell',
    method: 'POST',
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}

export function editRefundNewRecordSell(params,id){
  return request({
    url: '/seller/refundSellNew/editRefundSell',
    method: 'PUT',
    params:{id},
    data:params,
    headers: {'Content-Type': 'application/json'},
  })
}

export function getShopCardList3(params) {
  return request({
    url: `/seller/card/close/seller/cards/queryCardInFo`,
    method: 'GET',
    params
  })
}

/**
 * 获取销售充值/扣款单列表
 */
export function getRechargeRefundSalesList(params) {
  return request({
    url: `/seller/trade/recharge/getList`,
    method: 'GET',
    params
  })
}
/**
 * 获取客户列表下拉框
 */
export function getCustomerList(params) {
  return request({
    url: `/seller/trade/recharge/getCustomer`,
    method: 'GET',
    params
  })
}
/**
 * 获取商城名称
 */
export function getShopName() {
  return request({
    url: `/seller/trade/recharge/getShop`,
    method: 'GET'
  })
}
/**
 * 批量导入用户明细校验
 */
export function importMember(params) {
  return request({
    url: `/seller/trade/recharge/reportUser`,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    data: params
  })
}
/**
 * 添加销售充值/扣款单
 */
export function addMember(params) {
  return request({
    url: `/seller/trade/recharge/add`,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    data: params
  })
}
/**
 * 编辑销售充值/扣款单
 */
export function editMember(params) {
  return request({
    url: `/seller/trade/recharge/modify`,
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    data: params
  })
}
/**
 * 获取销售充值/扣款单详情
 */
export function getMemberDetail(sn) {
  return request({
    url: `/seller/trade/recharge/get`,
    method: 'GET',
    params: { sn:sn }
  })
}
/**
 * 检验是否可执行修改/取消操作
 */
export function checkMember(params) {
  return request({
    url: `/seller/trade/recharge/checkout`,
    method: 'GET',
    params: params
  })
}
/**
 * 取消销售充值/扣款单
 */
export function cancelMember(sn) {
  return request({
    url: `/seller/trade/recharge/cancel`,
    method: 'GET',
    params: { sn:sn }
  })
} 
/**
 * 销售充值/扣款单核销操作
 */
export function rechargeUnderWrite(params) {
  return request({
    url: `/seller/trade/recharge/rechargeUnderWrite`,
    method: 'POST',
    data: params
  })
}